import React, { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import CreditScore from '@mui/icons-material/CreditScore';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { formatMoney } from '@fingo/lib/helpers';
import { EDIT_CREDIT_LINE } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';

const EditCreditLine = ({
  masterEntity,
  creditLine,
  open,
  handleClose,
}) => {
  if (!creditLine) return null;
  const { name: masterEntityName } = useMemo(() => masterEntity, [masterEntity]);
  const {
    id: creditLineId,
    limitAmount,
    creditUsed,
  } = useMemo(() => creditLine, [creditLine]);
  const [newLimit, setNewLimit] = useState(limitAmount);
  const { addAlert } = useSnackBars();
  const [editCreditLine, { loading }] = useMutation(
    EDIT_CREDIT_LINE,
    {
      onCompleted: () => {
        addAlert({
          id: 'credit-line-updated',
          message: 'Línea de crédito actualizada correctamente',
        });
        handleClose();
      },
      onError: (e) => {
        addAlert({ id: 'credit-line-error', message: e.message, severity: 'error' });
      },
    },
  );
  useEffect(() => {
    setNewLimit(limitAmount);
  }, [limitAmount]);
  const onClickSave = () => {
    editCreditLine({ variables: { creditLineId, newLimit },
    });
  };
  const buttons = [
    {
      key: 'cancelButton',
      label: 'Cancelar',
      variant: 'outlined',
      onClick: handleClose,
    },
    {
      key: 'saveButton',
      label: 'Guardar',
      variant: 'contained',
      onClick: () => {
        onClickSave();
      },
    },
  ];
  const creditLineInfo = [
    {
      key: 'creditUsed',
      label: 'Crédito usado',
      value: creditUsed,
    },
    {
      key: 'limitAmount',
      label: 'Tope de línea actual',
      value: limitAmount,
    },
  ];
  return (
    <>
      <FingoDialog
        open={open}
        handleClose={handleClose}
        icon={CreditScore}
        title="Editar línea de crédito"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '450px',
          },
        }}
      >
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={0.5} alignItems="center">
            <Typography variant="h6" fontWeight={700}>Empresa:</Typography>
            <Typography variant="h5" textAlign="center">{masterEntityName}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {creditLineInfo.map(({
              key, label, value,
            }) => (
              <Stack key={key} direction="column" spacing={0.5}>
                <Typography variant="h6" fontWeight={700}>{label}:</Typography>
                <Typography variant="h5">${formatMoney(value)}</Typography>
              </Stack>
            ))}
          </Stack>
          <Stack
            direction="column"
            spacing={0.5}
          >
            <Typography variant="h6" fontWeight={700}>Nuevo tope de línea:</Typography>
            <ValidationTextFieldInput
              validationtype="text"
              key="companyCreditLineField"
              placeholder="Ingresa el nuevo monto"
              name="newCreditLineLimit"
              type="number"
              fullWidth
              InputProps={{
                startAdornment: '$',
              }}
              value={newLimit}
              onChange={(e) => setNewLimit(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickSave();
                }
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            {buttons.map(({
              key, label, variant, onClick,
            }) => (
              <LoadingButton
                key={key}
                variant={variant}
                onClick={onClick}
                size="small"
                loading={loading}
              >
                {label}
              </LoadingButton>
            ))}
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

EditCreditLine.propTypes = {
  masterEntity: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
  }).isRequired,
  creditLine: propTypes.shape({
    id: propTypes.string.isRequired,
    limitAmount: propTypes.number.isRequired,
    creditUsed: propTypes.number.isRequired,
  }),
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
};

EditCreditLine.defaultProps = {
  creditLine: null,
};

export default EditCreditLine;
